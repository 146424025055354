function senp1(plUrl, naMode, pageLevelDefinition = null, infiniteScrollEvent = null, purpose = 'all') {

    // Set and check for plUrl property. Cannot have a pixel without a plUrl
    this.plUrl = plUrl;

    this.sendPings = false;
    var self = this;
    this.executeWithProbability(0.01, function() {
        
        self.sendPings = true;
    });


    if(naMode){
        
        this.naMode = naMode;
    }

    this.pageLevelDefinition = pageLevelDefinition;

    if(pageLevelDefinition.engagementContainer !== undefined) {

        this.engagementContainer = pageLevelDefinition.engagementContainer;
    }
    else {

        this.engagementContainer = 'body';
    }

    this.purpose = purpose;

    this.uuid = this.generateUUID();

    this.connectionSpeed = this.getConnectionSpeed();

    this.infiniteScrollEvent = infiniteScrollEvent;
    
    if(this.purpose === 'init'){

        if(this.plUrl){

            this.sendPl("view");
        }

    }
}


senp1.prototype.setMarkers = function() {

    if(this.plUrl){

        if(this.infiniteScrollEvent !== null){

            this.listenForInfiniteScroll(this.infiniteScrollEvent);
        }

        this.createPageDepthMarkers();

        this.createEngagedPageTimers();

    }
}


senp1.prototype.sendPl = function(type = null, typeValue = null) {

    var plUrl = this.plUrl;
    var referrer = document.referrer;
    var xhttp = new XMLHttpRequest();

    var hostname = window.location.hostname;
    if(!hostname.startsWith("www.") && !hostname.startsWith("community.")){
        
        hostname = "www." + hostname;
    }

    // define basic url
    var timestamp;
    if(type === "view"){

        timestamp = this.extractKeyValue(window.sentinel, "initTimestamp") || Date.now();
    }
    else if (type === "footerPing") {

        timestamp = this.extractKeyValue(window.sentinel, "footerTimestamp") || Date.now();
    }
    else {

        timestamp = Date.now();
    }

    var url = "https://sentinelbi.com/" + hostname + "/" + plUrl + "?time=" + timestamp + "";

    // add permalink to url
    url = url + "&permalink=" + "/" + window.location.pathname.replace(/(^\/+|\/+$)/g, '') + '/';

    // add referrer to url, generated in real time
    if(referrer){
        url = url + "&referrer=" + referrer;
    }

    // add isActiveSession to url
    if(sessionStorage){

        if(sessionStorage.getItem('sn_ssTr')){
            url = url + "&isActiveSession=" + sessionStorage.getItem('sn_ssTr');
        }else{
            url = url + "&isActiveSession=" + 0;
        }

    }else{

        url = url + "&isActiveSession=" + 0;
    }
    

    if (this.connectionSpeed) {
        // add connection speed
        url = url + "&connSpeed=" + this.connectionSpeed;
    }

    if (this.naMode) {

        url = url + "&naMode=" + this.naMode;
    }

    if(this.pageLevelDefinition){
        
        if(this.pageLevelDefinition.intent){

            url = url + "&intent=" + encodeURIComponent(this.pageLevelDefinition.intent);
        }

        if(this.pageLevelDefinition.contentType){

            url = url + "&contentType=" + encodeURIComponent(this.pageLevelDefinition.contentType);
        }

        if(this.pageLevelDefinition.primaryTag){

            url = url + "&primaryTag=" + encodeURIComponent(this.pageLevelDefinition.primaryTag);
        }

        if(this.pageLevelDefinition.primaryCategory){

            url = url + "&primaryCategory=" + encodeURIComponent(this.pageLevelDefinition.primaryCategory);
        }

        if(this.pageLevelDefinition.networkCategory){

            url = url + "&networkCategory=" + encodeURIComponent(this.pageLevelDefinition.networkCategory);
        }

        if(this.pageLevelDefinition.adsTemplate){

            url = url + "&template=" + encodeURIComponent(this.pageLevelDefinition.adsTemplate);
        }

        if(this.pageLevelDefinition.articleType){

            url = url + "&articleType=" + encodeURIComponent(this.pageLevelDefinition.articleType);
        }

        if(this.pageLevelDefinition.userRef !== undefined){

            url = url + "&userRef=" + encodeURIComponent(this.pageLevelDefinition.userRef);
        }

        if(this.pageLevelDefinition.loggedIn !== undefined){

            url = url + "&loggedIn=" + encodeURIComponent(this.pageLevelDefinition.loggedIn);
        }

        if(this.pageLevelDefinition.plan !== undefined){

            url = url + "&plan=" + encodeURIComponent(this.pageLevelDefinition.plan);
        }

        if(this.pageLevelDefinition.referrerOverride){

            url = url + "&referrerOverride=" + encodeURIComponent(this.pageLevelDefinition.referrerOverride);
        }
    }

    // check for type and use if not pre-defined rely on referrer
    if(type == "view"){

        type = "pageVisit";

        // check referrer domain to update and set type
        if (referrer && referrer.trim() !== ""){

            var domain = window.location.hostname;
            var referrerDomain = null;

            if(referrer){
                referrerDomain = (new URL(referrer)).hostname;
            }

            if (referrerDomain === domain) {

                type = "pageView";
            } else {

                type = "pageVisit";
            }
        }
    }

    url = url + "&type=" + type;

    if(type=="footerPing"){

        url = url + "&typeValue=" + (this.extractKeyValue(window.sentinel, "footerTimestamp") || Date.now());
    }
    else{

        if(typeValue!==null){
    
            url = url + "&typeValue=" + typeValue;
        }
    }

    url = url + "&uuid=" + this.uuid;

    if(this.sendPings){

        url = url + "&isPing=1";
    }

    xhttp.open("GET", url, true);
    xhttp.send();
}

senp1.prototype.sendVideoPl = function(identifier, type, clientData, message = null, value = null, data = null) {

    var plUrl = this.plUrl;
    var referrer = document.referrer;
    var xhttp = new XMLHttpRequest();

    var hostname = window.location.hostname;
    if(!hostname.startsWith("www.")){
        hostname = "www." + hostname;
    }

    // define basic url
    var url = "https://sentinelbi.com/" + hostname + "/video/" + plUrl + "?time=" + Date.now() + "";

    // add referrer to url, generated in real time
    url = url + "&referrer=" + referrer;

    // add isActiveSession to url
    url = url + "&isActiveSession=" + (sessionStorage.getItem("sn_ssTr") ?? 0);

    if (this.connectionSpeed) {
        // add connection speed
        url = url + "&connSpeed=" + this.connectionSpeed;
    }

    if(this.naMode){
        
        url = url + "&naMode=" + this.naMode;
    }

    if(this.pageLevelDefinition){
        
        if(this.pageLevelDefinition.intent){

            url = url + "&intent=" + encodeURIComponent(this.pageLevelDefinition.intent);
        }

        if(this.pageLevelDefinition.contentType){

            url = url + "&contentType=" + encodeURIComponent(this.pageLevelDefinition.contentType);
        }

        if(this.pageLevelDefinition.primaryTag){

            url = url + "&primaryTag=" + encodeURIComponent(this.pageLevelDefinition.primaryTag);
        }

        if(this.pageLevelDefinition.primaryCategory){

            url = url + "&primaryCategory=" + encodeURIComponent(this.pageLevelDefinition.primaryCategory);
        }

        if(this.pageLevelDefinition.networkCategory){

            url = url + "&networkCategory=" + encodeURIComponent(this.pageLevelDefinition.networkCategory);
        }
    }

    // add type to url
    url = url + "&type=" + type;

    // add placement to url
    url = url + "&placement=" + identifier;

    // add fileName to url (data - videoUrl)
    if(data && data.videoUrl !== undefined){
        
        url = url + "&fileName=" + data.videoUrl;
    }

    // add isAutoPlay to url (data - isAutoplay - true or false)
    if(data && data.isAutoplay !== undefined){

        url = url + "&isAutoPlay=" + ((data.isAutoplay) ? "1" : "0");
    }

    // add permalink to url (clientData - clientData - url)
    if(clientData.clientData && clientData.clientData.url !== undefined) {
        
        url = url + "&permalink=" + "/" + clientData.clientData.url.replace(/(^\/+|\/+$)/g, '') + "/";
    }

    // add template to url (clientData - clientData - template)
    if(clientData.clientData && clientData.clientData.template !== undefined) {
        
        url = url + "&template=" + clientData.clientData.template;
    }
    
    // add source to url (clientData - clientData - source)
    if(clientData.clientData && clientData.clientData.source !== undefined) {

        url = url + "&source=" + clientData.clientData.source;
    }

    url = url + "&uuid=" + this.uuid;

    xhttp.open("GET", url, true);
    xhttp.send();
}

senp1.prototype.createPageDepthMarkers = function() {

    if(this.engagementContainer === 'body' || document.querySelector('#' +this.engagementContainer) === null){
        
        element = document.body;
    }
    else{

        element = document.querySelector('#' +this.engagementContainer);
    }

    var elementStartPos = element.getBoundingClientRect().top + document.documentElement.scrollTop;
    var elementHeight = element.offsetHeight;

    var snObj = this;

    var depthMarkerPercentages = [0, 20, 40, 60, 80, 100];
    depthMarkerPercentages.forEach(function(percentage) {

        setupPercentMarker(snObj, elementStartPos, elementHeight, percentage);
    });
}

function setupPercentMarker(snObj, elementStartPos, elementHeight, percentage) {
    
    var offsetHeight = (elementHeight * percentage / 100) + elementStartPos;
    
    function handleScroll() {
        
        if ((window.scrollY + window.innerHeight) > offsetHeight) {
            
            snObj.percentMarkerHandler(percentage);
            window.removeEventListener('scroll', handleScroll);
        }
    }
    
    window.addEventListener('scroll', handleScroll);
}

senp1.prototype.percentMarkerHandler = function(percentage) {
    
    this.sendPl("engagedDepth",percentage);
}

senp1.prototype.createEngagedPageTimers = function() {
    
    const maxEngagedTime = 30 * 60 * 1000;
    var totalTimeEngaged = 0;
    var timerPos = 0;
    var timers = [5000,10000,15000,25000,35000,45000,65000,85000,105000,145000,185000,225000,305000,385000,465000,625000,785000,945000,1265000,1585000,1905000];
    var snObj = this;
    var timerId;
    var pausedTimer = false;
    
    function recursiveTimeout() {
        
        if(totalTimeEngaged > maxEngagedTime){
            return;
        }
        
        timerId = setTimeout(recursiveTimeout, timers[timerPos]-totalTimeEngaged);
        
        if(!pausedTimer){
            
            if(totalTimeEngaged>0){
                
                snObj.engagedTimeHandler(timers[timerPos-1] / 1000);
            }
            
            totalTimeEngaged = timers[timerPos];
            timerPos += 1;
        }
        
    }
    
    function pauseTimers() {
        
        clearTimeout(timerId);
        pausedTimer = true;
    }
    
    function resumeTimers() {
        
        recursiveTimeout();
        pausedTimer = false;
    }
    
    document.addEventListener("visibilitychange", function() {
        
        if (document.visibilityState === "hidden") {
            
            pauseTimers();
        } else {
            
            resumeTimers();
        }
    });
    
    recursiveTimeout();
}

senp1.prototype.engagedTimeHandler = function(seconds) {
    
    this.sendPl("engagedSeconds", seconds);
}

senp1.prototype.getConnectionSpeed = function() {
    
    var connectionSpeed = null;
    if(navigator.connection){
        connectionSpeed = navigator.connection.downlink;
    }
    
    return connectionSpeed;
}

senp1.prototype.generateUUID = function() {
    
    var uuid;
    // Check if crypto.randomUUID() is supported
    if (typeof crypto.randomUUID === 'function') {
        // Use crypto.randomUUID() if supported
        uuid = crypto.randomUUID();
    } else {
        // Fallback method for generating UUIDs
        function fallbackUUID() {
            // Implementation of fallback UUID generation algorithm
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                var r = Math.random() * 16 | 0,
                v = c === 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        }
        
        // Use the fallback method to generate UUID
        uuid = fallbackUUID();
    }
    
    return uuid;
}

senp1.prototype.listenForInfiniteScroll = function(infiniteScrollEvent) {
    
    // Listen for infinite scroll events
    window.addEventListener(infiniteScrollEvent, (event) => {
        
        this.sendPl("pageView");
    });
}

senp1.prototype.sendFtPl = function() {

    if(this.sendPings){

        this.sendPl("footerPing");
    }
}

senp1.prototype.checkForGA = function() {

        // Check for GA4 gtag function
        if (typeof window.gtag === 'function') {

            // Check for gtag script fired
            if (window.dataLayer) {

                // Check for gtm.dom event
                let gtmDOM = window.dataLayer.some((entry) => {

                    if ('event' in entry) {

                        return entry['event'] === 'gtm.dom';
                    }
                });

                if (!gtmDOM) {

                    //GA4 is loaded but has not fired
                    this.sendPl("isGATrackerBlocked", 1);
                }
            } else {

                //GA4 is not available
                this.sendPl("isGATrackerBlocked", 1);
            }

        } else {

            //GA4 is not available
            this.sendPl("isGATrackerBlocked", 1);
        }
}

senp1.prototype.executeWithProbability = function(probability, callback) {

    if (Math.random() < probability) {
        
        callback();
    }
}

senp1.prototype.getSubdomain = function() {
    
    var hostname = window.location.hostname;
    var parts = hostname.split('.');
    if (parts.length <= 2) {
        return '';
    }
    return parts[0];
}

senp1.prototype.injectFooterJs = function() {
    
    // Create a new script element
    var footerJs = document.createElement('script');

    // Add sentinel footer script
    footerJs.textContent = `if(window.websiteSentinel) {

            window.websiteSentinel.sendFtPl();
            window.websiteSentinel.setMarkers();
        }

        //Check for GA on pageLoad after GA has tried to load
        window.onload = function() {

            if(window.websiteSentinel) {

                window.websiteSentinel.checkForGA();
            }
        };`;

        if (!this.extractKeyValue(window.sentinel, "footerTimestamp")) {
            // DOM is still loading, wait for it to be ready
            window.addEventListener('DOMContentLoaded', () => {
                this.appendToFooter(footerJs);
            });
        } else {
            // DOMContentLoaded has already fired, append immediately
            this.appendToFooter(footerJs);
        }
};

senp1.prototype.appendToFooter = function(script) {
    
    // Find the last footer element to append the script
    var footer = document.querySelectorAll('footer')[document.querySelectorAll('footer').length - 1]; 
            
    // Ensure this element exists
    if (footer) {
        
        footer.appendChild(script);
    } else {

        // if footer doesn't exist fall back and append to body
        document.body.appendChild(script);
    }
}

senp1.prototype.extractKeyValue = function(arr, key) {

    const pair = arr.find(([k, v]) => k === key);
    // If the pair is found, return the value (second element)
    return pair ? pair[1] : undefined; // Return undefined if key is not found
}

window.senp1 = senp1;

window.websiteSentinel = new senp1('senp.png', null, pageLevelDefinitionObj, null, 'init')
window.websiteSentinel.injectFooterJs();